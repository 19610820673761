<template lang="html" src="./serialNumberCSGParametersSelector.template.vue"></template>

<style lang="scss" src="./serialNumberCSGParametersSelector.scss"></style>

<script>

const i18nData = require('./serialNumberCSGParametersSelector.i18n');
export default {
  name: 'SerialNumberCSGParametersSelector',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * The part object
     */
    part: {
      type: Object,
      required: true
    }
  },
  watch: {
    part: {
      handler: function() {
        this.reset();
      }
    }
  },
  data() {
    return {
      checkbox: false,
      customHeight: 0.8,
      customSize: 6,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.checkbox = false;
      this.resetParameters();
    },
    resetParameters() {
      this.customHeight = this.part.serialNumberSettings.height;
      this.customSize = this.part.serialNumberSettings.size;
    }
  },
};
</script>
