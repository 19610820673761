var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "component-serialNumberCSGParametersSelector"
  }, [_c('v-checkbox', {
    on: {
      "change": _vm.resetParameters
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('SelectOtherParameters')) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.checkbox,
      callback: function ($$v) {
        _vm.checkbox = $$v;
      },
      expression: "checkbox"
    }
  }), _vm.checkbox ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('WarningCustomParameters')) + " ")]) : _vm._e(), _c('v-row', [_c('v-col', [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm.part.serialNumberSettings.mode === 'SUBTRACTION' ? _c('span', [_vm._v(_vm._s(_vm.$t('Depth')))]) : _c('span', [_vm._v(_vm._s(_vm.$t('Thickness')))])])])], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-slider', {
    attrs: {
      "step": "0.1",
      "min": "0.6",
      "max": "5",
      "disabled": !_vm.checkbox
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "number",
            "suffix": "mm",
            "step": "0.1",
            "min": "0.6",
            "max": "5",
            "disabled": !_vm.checkbox
          },
          model: {
            value: _vm.customHeight,
            callback: function ($$v) {
              _vm.customHeight = $$v;
            },
            expression: "customHeight"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.customHeight,
      callback: function ($$v) {
        _vm.customHeight = $$v;
      },
      expression: "\n          customHeight"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.$t('Size')))])])], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-slider', {
    attrs: {
      "step": "0.5",
      "min": "5",
      "max": "20",
      "disabled": !_vm.checkbox
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "number",
            "suffix": "mm",
            "step": "0.5",
            "min": "5",
            "max": "20",
            "disabled": !_vm.checkbox
          },
          model: {
            value: _vm.customSize,
            callback: function ($$v) {
              _vm.customSize = $$v;
            },
            expression: "customSize"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.customSize,
      callback: function ($$v) {
        _vm.customSize = $$v;
      },
      expression: "customSize"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }